<template>
  <div id="app">
    <div class="container">
      <h1>Agenda 47</h1>
      <div class="star-container">
        <div class="star" v-for="i in 13" :key="i"></div>
      </div>
      <img :src="require('./assets/image.png')" alt="Agenda 47 Logo" class="main-image">
      <p>Agenda 47 represents a visionary approach to governance and policy-making for the future. This initiative focuses on creating sustainable solutions, advancing technological innovation, and ensuring economic growth while maintaining social equity and environmental responsibility. By leveraging the power of blockchain and cryptocurrency, Agenda 47 aims to foster transparency, accountability, and efficiency in public and private sectors. Join us in our mission to shape a better future for all.</p>
      <p>With Agenda 47, we are committed to building a world where technology and innovation drive progress and create opportunities for everyone. Our platform provides a space for collaboration, ideation, and implementation of forward-thinking solutions that address the most pressing challenges of our time.</p>
      <div class="buttons">
        <a class="button red" href="https://pump.fun/38yDU5Z4i7Nu2qqG99ETpHj1ZjYoegPVXUDBekYPpump" target="_blank">Pump Fun</a>
        <a class="button blue" href="https://t.me/+mhteOEqVrsdiMDNh" target="_blank">Telegram</a>
        <a class="button white" href="https://x.com/Agenda47s" target="_blank">Twitter</a>
      </div>
      <div class="extra-images">
        <img :src="require('./assets/image.png')" alt="Agenda 47 Small Logo" class="small-image" v-for="i in 5" :key="i">
      </div>
    </div>
    <footer>@ Agenda47</footer>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
body {
  font-family: Arial, sans-serif;
  background-color: #121212;
  color: #ffffff;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.container {
  text-align: center;
  margin-top: 150px;
  position: relative;
  padding: 20px;
}

.star-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.star {
  width: 10px;
  height: 10px;
  background-color: #fff;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  position: absolute;
  animation: twinkle 2s infinite;
}

.star:nth-child(1) { top: 10%; left: 20%; animation-delay: 0.2s; }
.star:nth-child(2) { top: 20%; right: 20%; animation-delay: 0.4s; }
.star:nth-child(3) { top: 30%; left: 50%; animation-delay: 0.6s; }
.star:nth-child(4) { top: 40%; right: 30%; animation-delay: 0.8s; }
.star:nth-child(5) { top: 50%; left: 70%; animation-delay: 1s; }
.star:nth-child(6) { top: 60%; right: 10%; animation-delay: 1.2s; }
.star:nth-child(7) { top: 70%; left: 30%; animation-delay: 1.4s; }
.star:nth-child(8) { top: 80%; right: 50%; animation-delay: 1.6s; }
.star:nth-child(9) { top: 90%; left: 60%; animation-delay: 1.8s; }
.star:nth-child(10) { top: 10%; right: 40%; animation-delay: 2s; }
.star:nth-child(11) { top: 20%; left: 60%; animation-delay: 2.2s; }
.star:nth-child(12) { top: 30%; right: 50%; animation-delay: 2.4s; }
.star:nth-child(13) { top: 40%; left: 40%; animation-delay: 2.6s; }

@keyframes twinkle {
  0%, 100% { opacity: 1; transform: scale(1); }
  50% { opacity: 0.5; transform: scale(1.2); }
}

.main-image {
  max-width: 100%;
  height: auto;
  margin-top: 50px;
  border: 5px solid #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
  animation: fadeIn 2s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

h1 {
  font-size: 3em;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  animation: slideIn 1s ease-in-out;
}

@keyframes slideIn {
  from { transform: translateY(-50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

p {
  font-size: 1.2em;
  line-height: 1.6;
  max-width: 800px;
  margin: 20px auto;
}

.buttons {
  margin-top: 30px;
}

.button {
  display: inline-block;
  padding: 15px 30px;
  margin: 10px;
  font-size: 1.2em;
  color: #ffffff;
  text-align: center;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.button.red {
  background-color: #ff0000;
}

.button.red:hover {
  background-color: #ff4c4c;
}

.button.blue {
  background-color: #0000ff;
}

.button.blue:hover {
  background-color: #4c4cff;
}

.button.white {
  background-color: #ffffff;
  color: #000;
}

.button.white:hover {
  background-color: #e0e0e0;
}

.extra-images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.small-image {
  width: 50px;
  height: 50px;
  margin: 10px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: bounce 1.5s infinite alternate;
}

@keyframes bounce {
  from { transform: translateY(0); }
  to { transform: translateY(-10px); }
}

footer {
  text-align: center;
  padding: 20px;
  background-color: #121212;
  color: #ffffff;
  font-size: 1em;
  margin-top: 50px;
}
</style>
